import { useEffect, useRef } from "react"

export function HubspotMeetingEmbed() {
    const containerRef = useRef(null)

    useEffect(() => {
        const script = document.createElement("script")
        script.src =
            "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        script.type = "text/javascript"
        script.async = true
        if (containerRef.current) {
            containerRef.current.appendChild(script)
        }
    }, [])

    return (
        <div
            style={{ position: "relative", width: "100%", paddingTop: "125%" }}
        >
            <div
                className="meetings-iframe-container"
                data-src="https://meetings-eu1.hubspot.com/luca-fabian/kostenloses-beratungsgesprach-grundung?embed=true"
                ref={containerRef}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                    overflow: "hidden",
                }}
            />
        </div>
    )
}
